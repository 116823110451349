<template>
  <div>
    <UIForm />
  </div>
</template>

<script>
import UIForm from '@/components/ui/form/form.vue'

export default {
  name: 'Step4',

  components: {
    UIForm,
  },
}
</script>

<style></style>
